<template>
    <div class="mb-4">
        <div class="text-gray-400 title text-left duration-500 pc_show">我的积分</div>
        <div class="integral_box duration-500">
            <div
                class="text-xs text-gray-500 text-left info_box duration-500"
            >通过绑定设备、成功绑定设备、有效的投诉建议、用户提供的稿件内容被采纳的方式可获得积分</div>
            <div class="flex justify-between items-center sm:mt-4 integral">
                <div style="width:50%">
                    <div class="text-left text-gray-400 text-base duration-500">可用积分</div>
                    <div
                        class="text-primary sm:text-4xl font-bold text-left sm:mt-2 duration-500 number"
                    >{{ integralInfo.available }}</div>
                </div>
                <div style="width:50%">
                    <div class="text-left text-gray-400 text-base duration-500">已用积分</div>
                    <div
                        class="text-primary sm:text-4xl font-bold text-left sm:mt-2 duration-500 number"
                    >{{ integralInfo.used }}</div>
                </div>
            </div>
        </div>
        <div class="sm:mt-4 box_margin duration-500">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>兑换记录</div>
                <!-- <div class="text-gray-400">删除记录</div> -->
            </div>
            <div class="border_box conent_box duration-500">
                <div class="flex items-center flex-wrap" v-if="recordList.length > 0">
                    <div
                        style="width:33.33%"
                        class="flex justify-center items-center"
                        v-for="item in recordList"
                        :class="item > 2 ? 'mt-3' : ''"
                        :key="item.exchangeId"
                    >
                        <div style="width:95%">
                            <div class="w-full">
                                <img
                                    :src="item.productsImage"
                                    alt
                                    class="cursor-pointer"
                                    @click="exchangeDetail(item)"
                                />
                                <!-- <img src="../../../../assets/images/personal/personal8.png" alt /> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="null text-gray-500 flex justify-center py-3">暂无兑换记录</div>
                <div class="py-2 duration-500" v-if="maxPage > 1 && currPage != maxPage">
                    <div class="flex justify-center text-gray-500" @click="loadMore">-- 点击加载更多 --</div>
                </div>
            </div>
        </div>
        <div class="sm:mt-4 box_margin duration-500">
            <div class="table_title duration-500 flex justify-between items-center w-full">
                <div>为您推荐</div>
            </div>
            <div class="border_box conent_box flex items-center flex-wrap duration-500">
                <div
                    style="width:33.33%"
                    class="flex justify-center items-center"
                    v-for="item in product"
                    :class="item > 2 ? 'mt-3' : ''"
                    :key="item.integralProductsId"
                >
                    <div style="width:95%">
                        <div class="w-full relative">
                            <!-- <img src="../../../../assets/images/personal/personal3.png" alt /> -->
                            <img :src="item.productsImage" alt />
                            <div
                                class="duration-500 absolute z-10 icon_box flex justify-center items-center left-0 right-0"
                            >
                                <div
                                    class="duration-500 flex items-center justify-center cursor-pointer integral_btn text-white"
                                    :class="item.status ? 'bg-primary' : 'bg-gray-300'"
                                    @click="exchangeFunc(item)"
                                >
                                    积分兑换
                                    <span class="pc_show_inline">({{ item.productsIntegral }}分)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            integralInfo: {
                available: 0,
                total: 0,
                used: 0,
            },
            currPage: 1, // 当前页码
            pageSize: 6, // 每页的数量
            total: 0, // 总数量
            maxPage: 1,//最大页码数
            product: [],   // 兑换商品
            recordList: [], // 兑换记录
        };
    },
    created() {
        this.getUserIntegral()
        this.getIntegralProduct()
        this.exchangeRecord()
    },
    methods: {
        // 获取积分
        getUserIntegral() {
            this.$request({
                method: "get",
                url: this.$requestPath.integral,
                data: {}
            }).then(res => {
                // console.log("获取用户积分", res)
                if (res.code == 0) {
                    this.integralInfo = Object.assign({}, this.integralInfo, res.data)
                    // this.userInfo = Object.assign({}, this.userInfo, res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }

            }).catch(error => {
                console.log("获取用户积分", error)
            })
        },
        // 获取积分商品
        getIntegralProduct() {
            this.$request({
                method: "get",
                url: this.$requestPath.integralProduct,
                data: {}
            }).then(res => {
                console.log("获取积分商品", res)
                if (res.code == 0) {

                    // res.data.forEach(item=>{
                    //     item.status= false
                    // })
                    this.product = []
                    this.product.push(...res.data)
                    // this.userInfo = Object.assign({}, this.userInfo, res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }

            }).catch(error => {
                console.log("获取积分商品", error)
            })
        },

        // 获取积分兑换纪录
        exchangeRecord() {
            this.$request({
                method: "get",
                url: this.$requestPath.exchangeRecord,
                params: {
                    currPage: this.currPage,
                    pageSize: this.pageSize
                }
            }).then(res => {
                console.log("获取积分兑换纪录", res)
                if (res.code == 0) {
                    this.total = res.data.totalCount
                    this.maxPage = Math.ceil(this.total / this.pageSize)
                    if (this.currPage == 1) {
                        this.recordList = []
                    }
                    this.recordList.push(...res.data.list)
                    // this.$EventBus.$emit("toast", { type: 'success', message: res.msg })
                    // this.userInfo = Object.assign({}, this.userInfo, res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '无法获取积分兑换记录' })
                }

            }).catch(error => {
                console.log("获取积分兑换纪录", error)
            })
        },
        // 积分记录获取更多
        loadMore() {
            this.currPage = this.currPage + 1
            this.exchangeRecord()
        },
        // 积分兑换确认事件
        exchangeFunc(item) {
            if (item.status) {
                this.$store.commit("updateExchangeProduct", item)
                this.$router.push({ path: "/index/personal/exchange" })
            } else {
                this.$EventBus.$emit("toast", { type: 'success', message: '该商品暂时无法进行兑换' })
            }
            // let flag = localStorage.getItem("isMobile")
            // flag == "true" ? this.mobileExchangeInfo(item) : this.pcExchangeInfo(item)
        },

        exchangeDetail(item) {
            console.log(item)
            this.$router.push({
                path: '/index/sales/logistics', query: {
                    maintainId: item.exchangeId,
                    kdType: 3
                }
            })
            // this.$request({
            //     method: "get",
            //     url: this.$requestPath.exchangeDetail,
            //     params: {
            //         exchangeId: item.exchangeId
            //     }
            // }).then(res => {
            //     console.log("获取积分兑换纪录详情", res)
            // }).catch(error => {
            //     console.log("获取积分兑换纪录", error)
            // })
        },


        // 手机端兑换提示
        // mobileExchangeInfo(item) {
        //     this.$confirm(`确定使用${item.productsIntegral}积分兑换该商品吗？`, options).then(() => {
        //         this.exchangeProduct(item)
        //     }).catch(() => {
        //         console.log("点击了关闭")
        //     })
        //     let options = {
        //         title: '', // 默认无标题
        //         yes: {
        //             text: '确定',
        //             style: {
        //                 color: '#DD1821'
        //             }
        //         },
        //         no: {
        //             text: '取消',
        //             style: {
        //                 color: '#DD1821'
        //             }
        //         }
        //     }
        // },
        // pc端兑换提示
        // pcExchangeInfo(item) {
        //     const h = this.$createElement;
        //     this.$msgbox({
        //         title: '提示',
        //         message: h('p', null, [
        //             h('span', null, `确定使用${item.productsIntegral}积分兑换该商品吗？`),
        //         ]),
        //         showCancelButton: true,
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         beforeClose: (action, instance, done) => {
        //             if (action === 'confirm') {
        //                 done();
        //                 this.exchangeProduct(item)
        //             } else {
        //                 done();
        //             }
        //         }
        //     }).then(() => {
        //         this.$EventBus.$emit("toast", { type: 'success', message: "退出登录成功" })
        //     }).catch(() => { })
        // },
    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}
.integral_btn {
    width: 60%;
    display: flex;
}
.icon_box {
    bottom: 1.25rem;
}
@media screen and (max-width: 640px) {
    .img_box {
        width: 4rem;
    }
    // .integral_btn {
    //     background: var(--primary);
    //     width: 80%;
    //     display: flex;
    //     font-size: 0.75rem;
    // }
    .base_right {
        width: calc(100% - 5rem);
        font-size: 0.75rem;
        img {
            width: 0.375rem !important;
        }
        .info_base {
            margin: 0.3125rem 0;
        }
    }
    .base_info {
        padding: 0.625rem 0.25rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 0;
        border: none !important;
    }
    .text_left {
        width: 3.125rem;
        display: inline-block;
        text-align: left;
    }
    li {
        font-size: 0.75rem;
    }
    li:not(:last-child) {
        margin-bottom: 0.25rem;
    }
    ._inline {
        display: none;
    }
    .pc_flex_show {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .icon_box {
        bottom: 0.625rem;
    }
    .integral_btn {
        border-radius: 0.25rem;
        height: 1.875rem;
        font-size: 0.75rem;
    }
    ._inline {
        display: none;
    }
    .pc_flex_show {
        display: none;
    }
    .pc_show_inline {
        display: none;
    }

    .integral_box {
        border: 0.0625rem solid var(--border-base);
        padding: 0.625rem;
        .info_box {
            text-align: center !important;
        }
        .integral {
            margin-top: 0.9375rem;
            div {
                text-align: center;
            }
            .number {
                font-size: 1.5625rem !important;
            }
        }
    }

    .box_margin {
        margin-top: 0.625rem;
    }

    .phone_flex_show {
        display: flex;
    }

    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .pc_flex_show {
        display: flex;
    }
    .pc_show_inline {
        display: inline-block;
    }
    li {
        list-style-type: disc !important;
    }
    li:not(:last-child) {
        margin-bottom: 0.625rem;
    }
    .table_title {
        padding: 0.5rem 0;
    }
    .conent_box {
        padding: 1rem;
    }
    .img_box {
        width: 6.25rem;
    }
    .base_right {
        width: calc(100% - 8rem);
    }
    .info_base {
        margin: 1rem 0;
    }
    .text_left {
        width: 3.75rem;
        display: inline-block;
        text-align: left;
    }
}
@media screen and (min-width: 900px) {
    .integral_btn {
        height: 2.5rem;
    }
    ._inline {
        display: block;
    }
    .pc_flex_show {
        display: flex;
    }
    .btn {
        width: 49%;
        margin: 0 !important;
    }
    .phone_flex_show {
        display: none;
    }

    .title {
        font-size: 1.875rem;
    }
}
</style>